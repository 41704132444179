// Dependencies

import { Link as RouterLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

// Material UI

import { Box, Container, Divider, Link, Typography } from '@mui/material';

// Components and functions

import AppPage from '../pages/appPage/AppPage';
import ModalAlert from '../common/modal/alert/ModalAlert';
import ModalMap from './../common/modal/map/ModalMap';
import { AboutButton } from '../pages/appPage/AppPage.styled';
import { Location } from '../interfaces/location.interfaces';
import { formSpinnerActive } from '../redux/formSpinner/formSpinner.slices';
import { routeNames } from '../routes/Routes';

// Props

interface AboutProps {}

const About: React.FC<AboutProps> = () => {
  const [modalOpenAlert, setModalOpenAlert] = useState(false);
  const [modalOpenMap, setModalOpenMap] = useState(false);

  const dispatch = useDispatch();

  const center: Location = {
    placename: 'Geelong, Victoria',
    lat: -38.16037253267879,
    lng: 144.3498077459755,
  };

  const handleModalClosedAlert = (source: string) => {
    console.log(source);
    setModalOpenAlert(false);
  };

  const displayModalAlert = () => (
    <ModalAlert
      buttonCaption='close'
      content='This is an alert modal ceated using Material UI'
      onClose={handleModalClosedAlert}
      open={modalOpenAlert}
      title='Material UI Alert Modal'
    />
  );

  const handleModalClosedMap = () => {
    console.log('map closed');
    setModalOpenMap(false);
  };

  const displayModalMap = () => <ModalMap center={center} onClose={handleModalClosedMap} open={modalOpenMap} />;

  const handleSpinner = () => {
    dispatch(formSpinnerActive(true));
    setTimeout(() => {
      dispatch(formSpinnerActive(false));
    }, 3000);
  };

  return (
    <AppPage>
      <Container>
        <Typography variant='h5'>App</Typography>
        <Divider />
        <Link component={RouterLink} to={`${routeNames.dashboard}`} variant='body1'>
          Dashboard
        </Link>
        <Divider />
        <AboutButton onClick={handleSpinner} variant='outlined'>
          Create Form Spinner
        </AboutButton>
        <Box className={'form-spacer'} />
        <AboutButton onClick={() => alert('MUI is working')} variant='outlined'>
          Display Alert
        </AboutButton>
        <Box className={'form-spacer'} />
        <AboutButton onClick={() => setModalOpenAlert(true)} variant='outlined'>
          Display Alert Modal
        </AboutButton>
        <Box className={'form-spacer'} />
        <AboutButton onClick={() => setModalOpenMap(true)} variant='outlined'>
          Display Map Modal
        </AboutButton>
      </Container>
      {modalOpenAlert && displayModalAlert()}
      {modalOpenMap && displayModalMap()}
    </AppPage>
  );
};

export default About;
