// Dependencies

import styled from 'styled-components';

// Material UI

import { Box, Button } from '@mui/material';

export const Wrapper = styled(Box)`
  .appbar {
    margin-bottom: 1rem;
  }

  .appbar-title {
    flex-grow: 1;
  }

  .appbar-toolbar {
    background-color: ${(props) => (props.theme.palette.mode === 'light' ? props.theme.palette.primary : props.theme.palette.primary.dark)};
  }

  .form-spacer {
    margin-bottom: 1rem;
  }

  .MuiDivider-root {
    margin-block: 1rem;
  }
`;

export const AboutButton = styled(Button)`
  min-width: 230px;
`;
