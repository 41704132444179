// Dependencies

import styled from 'styled-components';

// Material UI

import { Box } from '@mui/material';

export const Wrapper = styled(Box)`
  .appbar {
    height: 7vh;
    position: relative;
  }

  .appbar-title {
    margin-left: ${(props) => props.theme.spacing(2)};
    flex: 1;
  }

  .appbar-toolbar {
    background-color: ${(props) => (props.theme.palette.mode === 'light' ? props.theme.palette.primary : props.theme.palette.primary.dark)};
  }

  .map-container {
    display: flex;
    justify-content: center;
  }

  .map-container-map {
    height: 93vh;
    width: 100%;
  }
`;
