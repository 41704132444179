// Dependencies

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

// Material UI

import { Box } from '@mui/material';

// Components and functions

import { GoogleLatLng, GoogleMap, GoogleMapInfoWindow, MapMarker } from '../../../interfaces/map.interfaces';
import { Location } from '../../../interfaces/location.interfaces';
import { MapAction, MapActions } from '../../../interfaces/map.interfaces';

// Styles

import mapStyles from './mapStyles';

// Props

interface MapProps {
  allowClickMarker?: boolean;
  center: Location;
  fullScreenControl?: boolean;
  mapMarkers?: MapMarker[];
  mapType?: google.maps.MapTypeId;
  mapTypeControl?: boolean;
  zoom?: number;
}

let openInfoWindow: GoogleMapInfoWindow;

const Map = forwardRef<MapAction, MapProps>(
  ({ allowClickMarker = false, center, fullScreenControl = false, mapMarkers = null, mapType, mapTypeControl = false, zoom = 12 }, ref) => {
    const [map, setMap] = useState<GoogleMap>();
    const [mapMarker, setMapMarker] = useState<MapMarker>();
    const mapRef = useRef<HTMLDivElement>(null);

    // Initialize and manage google map ------------------------------------------------------------

    const defaultMapStart = (): void => {
      const defaultAddress = new google.maps.LatLng(center.lat, center.lng);
      initMap(zoom, defaultAddress);
    };

    const initMap = (zoomLevel: number, address: GoogleLatLng): void => {
      if (mapRef.current) {
        setMap(
          new google.maps.Map(mapRef.current, {
            center: address,
            fullscreenControl: fullScreenControl,
            mapTypeControl: mapTypeControl,
            mapTypeId: mapType,
            styles: mapStyles as google.maps.MapTypeStyle[],
            zoom: zoomLevel,
          })
        );
      }
    };

    const startMap = (): void => {
      if (!map) {
        defaultMapStart();
      }
    };

    // eslint-disable-next-line
    useEffect(startMap, [map]);

    // Handle allowClickMarker ---------------------------------------------------------------------

    // The hook between the allowClickMarker and the displaying of the marker itself it the change
    // in the mapMarker state. This section instantiates the listener for the click on the map
    // and registers the callback. That callback calls coordinateToAddress to update the mapMarker
    // state.

    const coordinateToAddress = async (coordinate: GoogleLatLng) => {
      const geocoder = new google.maps.Geocoder();
      await geocoder.geocode({ location: coordinate }, function (results, status) {
        if (status === 'OK') {
          setMapMarker({
            address: results[0].formatted_address,
            lat: coordinate.lat(),
            lng: coordinate.lng(),
          });
        }
      });
    };

    const initEventListener = (): void => {
      if (map && allowClickMarker) {
        google.maps.event.addListener(map, 'click', function (e) {
          coordinateToAddress(e.latLng);
        });
      }
    };

    // eslint-disable-next-line
    useEffect(initEventListener, [map]);

    // Provide the global addMarker method ---------------------------------------------------------

    // This method is called by either addSingleMarker (triggered by a change in mapMarker state) or
    // the map marker collection handler (triggered by a change in the map state when the component
    // is instantiated).

    const addMarker = (address: string, location: GoogleLatLng): void => {
      const marker = new google.maps.Marker({
        position: location,
        map: map,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: address,
      });

      marker.addListener('click', () => {
        if (openInfoWindow) openInfoWindow.close();
        openInfoWindow = infoWindow;
        infoWindow.open(map, marker);
      });
    };

    // Handle displaying marker on map -------------------------------------------------------------

    // A changed in the mapMarker state triggers the addSingleMarker method that calls the addMarker
    // method passing in the mapMarker details from the listener registered to the click event.

    const addSingleMarker = (): void => {
      if (mapMarker) {
        addMarker(mapMarker.address, new google.maps.LatLng(mapMarker.lat, mapMarker.lng));
      }
    };

    // eslint-disable-next-line
    useEffect(addSingleMarker, [mapMarker]);

    // Handle mapMarkers collection ----------------------------------------------------------------

    // A change in the map state (usually when the component is loaded) triggers the addMapMarkers
    // method that then checks that the map is displayed and whether there is a mapMarkers collection.
    // If there is a collection it iterates the markers and call the global addMarker.

    const addMapMarkers = (): void => {
      if (map && mapMarkers) {
        mapMarkers.map((marker) => addMarker(marker.address, new google.maps.LatLng(marker.lat, marker.lng)));
      }
    };

    // eslint-disable-next-line
    useEffect(addMapMarkers, [map]);

    // Handle external connection to recenter map --------------------------------------------------

    // This is the external method that may be called by a parent component to expose the registered
    // MapActions. It uses react's forwardRef methodology.

    const handleMapAction = (mapAction: MapActions) => {
      console.log('recenter');
      switch (mapAction) {
        case MapActions.recenter:
          if (map) {
            map?.panTo({ lat: center.lat, lng: center.lng });
            map?.setZoom(zoom);
          }
          return;
      }
    };

    useImperativeHandle(ref, () => ({ handleMapAction }));

    return (
      <Box className={'map-container'}>
        <Box className={'map-container-map'} ref={mapRef}></Box>
      </Box>
    );
  }
);

export default Map;
