// Dependencies

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Components and functions

import { FormSpinnerRedux } from '../../interfaces/formSpinner.interfaces';
import { RootState } from '../store';

// State

const initialState: FormSpinnerRedux = {
  isActive: false,
};

// Slice

const formSpinnerSlice = createSlice({
  name: 'formSpinner',
  initialState: initialState,
  reducers: {
    formSpinnerActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
  },
});

// Exports

export const { formSpinnerActive } = formSpinnerSlice.actions;

export const getFormSpinnerSelector = (state: RootState) => state.formSpinner;

export default formSpinnerSlice.reducer;
