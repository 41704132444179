// Dependencies

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Components and functions

import { MapRedux } from '../../interfaces/map.interfaces';
import { RootState } from '../store';

// State

const initialState: MapRedux = {
  isLoaded: false,
};

// Slice

const mapSlice = createSlice({
  name: 'map',
  initialState: initialState,
  reducers: {
    mapIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload;
    },
  },
});

// Exports

export const { mapIsLoaded } = mapSlice.actions;

export const getMapSelector = (state: RootState) => state.map;

export default mapSlice.reducer;
