// Material UI

import { AppBar, Toolbar, Typography } from '@mui/material';

// Components and functions

import ThemeToggle from './../../styling/ThemeToggle';

// Styling

import { Wrapper } from './AppPage.styled';

// Props

interface AppPageProps {}

const AppPage: React.FC<AppPageProps> = (props) => {
  const { children } = props;
  return (
    <Wrapper>
      <AppBar className={'appbar'} elevation={0} position='static'>
        <Toolbar className={'appbar-toolbar'}>
          <Typography className={'appbar-title'} variant='h6'>
            React Development
          </Typography>
          <ThemeToggle />
        </Toolbar>
      </AppBar>
      {children}
    </Wrapper>
  );
};

export default AppPage;
