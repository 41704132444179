// Dependencies

import { Link as RouterLink } from 'react-router-dom';

// Material UI

import { Box, Container, Divider, Link, Typography } from '@mui/material';

// Components and functions

import AppPage from './../pages/appPage/AppPage';
import { routeNames } from '../routes/Routes';

// Props

interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  return (
    <AppPage>
      <Container>
        <Typography variant='h5'>Dashboard</Typography>
        <Divider />
        <Link component={RouterLink} to={`${routeNames.about}`} variant='body1'>
          About
        </Link>
        <Box className='form-spacer' />
        <Link component={RouterLink} to={`${routeNames.mapDisplay}`} variant='body1'>
          Map Display
        </Link>
        <Box className='form-spacer' />
        <Link component={RouterLink} to={`${routeNames.mapPlacesSearch}`} variant='body1'>
          Map Places Search
        </Link>
        <Box className='form-spacer' />
        <Link component={RouterLink} to={'/bad'} variant='body1'>
          Bad Link
        </Link>
      </Container>
    </AppPage>
  );
};

export default Dashboard;
