export enum MapActions {
  recenter = 'recenter',
}

export interface MapAction {
  handleMapAction(mapAction: MapActions): void;
}

export interface MapMarker {
  address: string;
  lat: number;
  lng: number;
}

export interface MapRedux {
  isLoaded: boolean;
}

export type GoogleLatLng = google.maps.LatLng;
export type GoogleMap = google.maps.Map;
export type GoogleMapAutocompletePrediction = google.maps.places.QueryAutocompletePrediction;
export type GoogleMapInfoWindow = google.maps.InfoWindow;
export type GoogleMapPlaceResult = google.maps.places.PlaceResult;
