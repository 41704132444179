// Dependencies

import styled from 'styled-components';

// Material UI

import { Box } from '@mui/material';

export const Wrapper = styled(Box)`
  .map-container {
    display: flex;
    justify-content: center;
  }
  .map-container-map {
    height: 75vh;
    width: 100%;
  }
`;
