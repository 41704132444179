// Dependencies

import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

// Components and functions

import Error from './components/Error';
import routes from './routes/Routes';
import { mapIsLoaded } from './redux/map/map.slices';
import { mapUtilsLoadApi } from './common/map/mapUtils';

// Props

interface AppProps {}

const App: React.FC<AppProps> = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const googleMapScript = mapUtilsLoadApi();
    googleMapScript.addEventListener('load', function () {
      dispatch(mapIsLoaded(true));
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.component} />
      ))}
      <Route path='*' element={<Error />} />
    </Routes>
  );
};

export default App;
