// Dependencies

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

// Material UI

import { CssBaseline } from '@mui/material';

// Components and functions

import App from './App';
import FormSpinner from './common/form/formSpinner/FormSpinner';
import ThemeProvider from './styling/ThemeProvider';
import store from './redux/store';
import { GlobalStyle } from './globalStyles';
import { BrowserRouter } from 'react-router-dom';

const rootEl = document.getElementById('root');

function render() {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider>
          <CssBaseline />
          <GlobalStyle />
          <FormSpinner />
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>,
    rootEl
  );
}

if (module.hot) {
  module.hot.accept('./App', function () {
    setTimeout(render);
  });
}

render();
