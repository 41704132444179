// Components and functions

import About from '../components/About';
import Dashboard from '../components/Dashboard';
import MapDisplay from '../components/mapDisplay/MapDisplay';
import MapPlacesSearch from '../components/MapPlacesSearch';
import { Route } from '../interfaces/route.interfaces';

export enum routeNames {
  'about' = '/about',
  'dashboard' = '/',
  'mapDisplay' = '/map-display',
  'mapPlacesSearch' = '/map-places-search',
}

const routes: Route[] = [
  {
    component: <About />,
    name: 'About',
    path: routeNames.about,
  },
  {
    component: <Dashboard />,
    name: 'Dashboard',
    path: routeNames.dashboard,
  },
  {
    component: <MapDisplay />,
    name: 'MapDisplay',
    path: routeNames.mapDisplay,
  },
  {
    component: <MapPlacesSearch />,
    name: 'MapPlacesSearch',
    path: routeNames.mapPlacesSearch,
  },
];

export default routes;
