// Dependencies

import { configureStore } from '@reduxjs/toolkit';

// Components and functions

import formSpinner from './formSpinner/formSpinner.slices';
import map from './map/map.slices';

const store = configureStore({
  reducer: {
    formSpinner,
    map,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
