// Dependencies

import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Material UI

import { Box, Container, Divider, Link, Typography } from '@mui/material';

// Components and functions

import AppPage from '../../pages/appPage/AppPage';
import Map from '../../common/map/map/Map';
import { Location } from '../../interfaces/location.interfaces';
import { getMapSelector } from '../../redux/map/map.slices';
import { routeNames } from '../../routes/Routes';

// Styles

import { Wrapper } from './MapDisplay.styled';

// Props

interface MapDisplayProps {}

const MapDisplay: React.FC<MapDisplayProps> = () => {
  const map = useSelector(getMapSelector);

  const center: Location = {
    placename: 'Geelong, Victoria',
    lat: -38.16037253267879,
    lng: 144.3498077459755,
  };

  return (
    <Wrapper>
      <AppPage>
        <Container>
          <Typography variant='h5'>Map Display</Typography>
          <Divider />
          <Link component={RouterLink} to={`${routeNames.dashboard}`} variant='body1'>
            Dashboard
          </Link>
          <Divider />
          {!map.isLoaded ? (
            <Box>loading map...</Box>
          ) : (
            <Map allowClickMarker={true} center={center} mapType={google.maps.MapTypeId.ROADMAP} mapTypeControl={true} />
          )}
        </Container>
      </AppPage>
    </Wrapper>
  );
};

export default MapDisplay;
