// Dependencies

import { useSelector } from 'react-redux';
import { useRef } from 'react';

// Material UI

import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Button, Dialog, IconButton, Toolbar, Typography } from '@mui/material';

// Components and functions

import Map from '../../map/map/Map';
import { Location } from '../../../interfaces/location.interfaces';
import { MapAction, MapActions, MapMarker } from '../../../interfaces/map.interfaces';
import { getMapSelector } from '../../../redux/map/map.slices';

// Styles

import { Wrapper } from './ModalMap.styled';

// Props

interface ModalMapsProps {
  center: Location;
  onClose: () => void;
  open: boolean;
}

const mapMarkers: MapMarker[] = [
  {
    address: '69 Smeaton Cl, Lara, VIC, 3212',
    lat: -38.02676159936029,
    lng: 144.41995678470383,
  },
  { address: '58 Curletts Rd, Lara VIC 3212', lat: -38.01480468947189, lng: 144.40326572019242 },
];

const ModalMap: React.FC<ModalMapsProps> = ({ center, onClose, open }) => {
  const MapActionRef = useRef<MapAction>(null);
  const map = useSelector(getMapSelector);

  const recenterPage = () => {
    console.log('recenter');
    MapActionRef.current?.handleMapAction(MapActions.recenter);
  };

  return (
    <Dialog fullScreen open={open} onClose={() => onClose()}>
      <Wrapper>
        <AppBar className={'appbar'} elevation={0}>
          <Toolbar className={'appbar-toolbar'}>
            <IconButton edge='start' color='inherit' onClick={() => onClose()} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography className='appbar-title' variant='h6'>
              {center.placename}
            </Typography>
            <Button onClick={() => recenterPage()} style={{ color: 'white' }}>
              <CenterFocusStrongIcon />
              <Typography variant='body2'>Recenter</Typography>
            </Button>
          </Toolbar>
        </AppBar>
        {!map.isLoaded ? (
          <Box>loading map...</Box>
        ) : (
          <Map center={center} mapMarkers={mapMarkers} mapType={google.maps.MapTypeId.ROADMAP} mapTypeControl={true} ref={MapActionRef} />
        )}
      </Wrapper>
    </Dialog>
  );
};

export default ModalMap;
