// Material UI

import { Box } from '@mui/material';

// Dependencies

import styled from 'styled-components';

export const Wrapper = styled(Box)`
  .MuiBackdrop-root {
    color: #fff;
    z-index: ${(props) => props.theme.zIndex.drawer + 1};
  }
`;
