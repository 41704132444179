// Dependencies

import { throttle } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

// Material UI

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';

// Components and functions

import { GoogleMapAutocompletePrediction } from '../../../../interfaces/map.interfaces';

// Props

interface MapPlacesProps {}

const autocompleteService = { current: null };

const MapPlaces: React.FC<MapPlacesProps> = () => {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly GoogleMapAutocompletePrediction[]>([]);
  const [value, setValue] = useState<GoogleMapAutocompletePrediction | null>(null);

  var service = new google.maps.places.PlacesService(document.createElement('div'));

  const fetch = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: readonly GoogleMapAutocompletePrediction[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly GoogleMapAutocompletePrediction[]) => {
      if (active) {
        let newOptions: readonly GoogleMapAutocompletePrediction[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleSelection = (newValue: GoogleMapAutocompletePrediction | null) => {
    if (newValue) {
      console.log('selection >>', newValue);
      service.getDetails({ placeId: newValue.place_id }, function (place, status) {
        console.log(place);
      });
    } else {
      console.log('no selection');
    }
  };

  return (
    <Autocomplete
      autoComplete
      filterOptions={(x) => x}
      filterSelectedOptions
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      id='google-map'
      includeInputInList
      options={options}
      sx={{ width: '100%' }}
      value={value}
      onChange={(event: any, newValue: GoogleMapAutocompletePrediction | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        handleSelection(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} fullWidth />}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.place_id}>
            <Grid container alignItems='center'>
              <Grid item>
                <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
              </Grid>
              <Grid item xs>
                <span>{option.description}</span>;
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default MapPlaces;
