// Material UI

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

// Props

interface ModalAlertProps {
  buttonCaption?: string;
  content: string;
  onClose: (source: string) => void;
  open: boolean;
  title: string;
}

const ModalAlert: React.FC<ModalAlertProps> = ({ buttonCaption, content, onClose, open, title }) => {
  if (!open) return null;

  return (
    <Dialog open={open} onClose={() => onClose('off screen')}>
      <DialogTitle>{title ? title : 'No title'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content ? content : 'No content'}</DialogContentText>
      </DialogContent>
      {buttonCaption && (
        <DialogActions>
          <Button onClick={() => onClose('from button')} color='primary' autoFocus>
            {buttonCaption}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalAlert;
