// Dependencies

import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Material UI

import { Box, Container, Divider, Link, Typography } from '@mui/material';

// Components and functions

import AppPage from '../pages/appPage/AppPage';
import MapPlaces from '../common/map/map/mapPlaces/MapPlaces';
import { getMapSelector } from '../redux/map/map.slices';
import { routeNames } from '../routes/Routes';

// Props

interface MapPlacesSearchProps {}

const MapPlacesSearch: React.FC<MapPlacesSearchProps> = () => {
  const map = useSelector(getMapSelector);
  return (
    <AppPage>
      <Container>
        <Typography variant='h5'>Map Places Search</Typography>
        <Divider />
        <Link component={RouterLink} to={`${routeNames.dashboard}`} variant='body1'>
          Dashboard
        </Link>
        <Divider />
        {!map.isLoaded ? (
          <Box>loading map...</Box>
        ) : (
          <>
            <MapPlaces />
            <Box className='form-spacer' />
            <Typography variant='body1'>This is an example of the MUI AutoComplete component. It returns a Google maps places payload.</Typography>
          </>
        )}
      </Container>
    </AppPage>
  );
};

export default MapPlacesSearch;
