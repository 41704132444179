export const mapUtilsLoadApi = () => {
  const mapsUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=geometry,places`;

  const scripts = document.getElementsByTagName('script');

  // Iterate the scripts collection and, if we find a match we can return it.

  for (let i = 0; i < scripts.length; i++) {
    if (scripts[i].src.indexOf(mapsUrl) === 0) {
      return scripts[i];
    }
  }

  // We don't have a map script loaded so we can create one and return it

  const googleMapScript = document.createElement('script');

  googleMapScript.src = mapsUrl;
  googleMapScript.async = true;
  googleMapScript.defer = true;

  window.document.body.appendChild(googleMapScript);

  return googleMapScript;
};
