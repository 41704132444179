// Dependencies

import { useNavigate, useLocation } from 'react-router-dom';

// Material UI

import { Box, Button, Container, Divider, Typography } from '@mui/material';

// Components and functions

import AppPage from '../pages/appPage/AppPage';
import { routeNames } from '../routes/Routes';

// Props

interface ErrorProps {}

const Error: React.FC<ErrorProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <AppPage>
      <Container>
        <Typography component='div' variant='h5'>
          Error
        </Typography>
        <Divider />
        <Typography component='div' variant='h6'>
          Page Not Found
        </Typography>
        <Box className={'form-spacer'} />
        <Typography component='div' variant='body1'>
          {location.pathname}
        </Typography>
        <Box className={'form-spacer'} />
        <Button color='warning' onClick={() => navigate(`${routeNames.dashboard}`)} variant='outlined'>
          Back
        </Button>
      </Container>
    </AppPage>
  );
};

export default Error;
